import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from "./Pages/Home";
import AuthLogin from "./Pages/AuthLogin";
import Dashboard from "./Pages/Dashboard";
import Medicine from "./Pages/Medicine";
import MedicineSearch from "./Pages/MedicineSearch";
import MedicineUpload from "./Pages/MedicineUpload";
import StartScreen from "./Pages/StartScreen";
import MedicineSingle from "./Pages/MedicineSingle";
import Settings from "./Pages/Settings";

function App() {
  return (
    <>
      <Router>
         <div className="main-container">
          <Routes>
             <Route path="/login" element={<AuthLogin />}/>  
             <Route path="/" element={<Home />}/>  
             <Route element={<Dashboard />}>
                   <Route path="/start" element={<StartScreen/>}/> 
                   <Route path="/medicines" element={<Medicine/>}/> 
                   <Route path="/medicine-upload" element={<MedicineUpload />}/> 
                   <Route path="/medicine-search" element={<MedicineSearch/>}/> 
                   <Route path="/medicament/:id" element={<MedicineSingle/>}/> 
                   <Route path="/settings" element={<Settings />}/> 
               </Route>
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
