import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../reduxAuth/authSlice';

function MedicineSingle() {
    const {user}                                                        = useSelector((state) => state.auth);
    const [processing, setProcessing]                                   = useState(false);
    const [selectedMedicine, setSelectedMedicine]                       = useState([]);

    const params                                                        = useParams();

    const dispatch                                                      = useDispatch();

    useEffect(()=> {
        getThisMedicine();
    },[])

    const getThisMedicine = async () => {
        try{
            setProcessing(true);
           
            const res = await axios.get(CONSTANTS.API_URL +"medicine/single/"+ params.id, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
  
            setSelectedMedicine(res.data);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
            if(err.response.status === 403){
                  localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                  dispatch(logout());
                  toast.error("Session has timeout out.");

                  setTimeout(() => {
                    document.location.href("/login");
                  }, 4000);
            }
        }
    }

    const handleDiscontinue = async () => {
        try{
            setProcessing(true);

            let configureDiscontinued  = {
                method: "PUT",
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL +"medicine/discontinued/product/"+ params.id,
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            }

            const resData = await axios.request(configureDiscontinued);
                
            //console.log(resData);
            setSelectedMedicine(resData.data);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }

    const handleContinue = async () => {
        try{
            setProcessing(true);

            let configureDiscontinued  = {
                method: "PUT",
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL +"medicine/continued/product/"+ params.id,
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            }

            const resData = await axios.request(configureDiscontinued);
                
            //console.log(resData);
            setSelectedMedicine(resData.data);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
  return (
    <div className="box-page">
      <h3>Medicine Single</h3>
      <div className="box-body">
        
        <div className="row">
        {
             selectedMedicine && (
              <div className="section-semi-table">
                  <table className="table table-striped">
                    <tbody>
                        <tr>
                          <td>Proprietary Name</td>
                          <td>:</td>
                          <td>{selectedMedicine.proprietaryName}</td>
                        </tr>
                        <tr>
                          <td>Active Ingredients</td>
                          <td>:</td>
                          <td>{selectedMedicine.activeIngredient}</td>
                        </tr>
                        <tr>
                          <td>Schedule</td>
                          <td>:</td>
                          <td>{selectedMedicine.schedule}</td>
                        </tr>
                        <tr>
                          <td>Nappi Code</td>
                          <td>:</td>
                          <td>{selectedMedicine.nappiCode}</td>
                        </tr>
                        <tr>
                          <td>Pack Size</td>
                          <td>:</td>
                          <td>{selectedMedicine.packSize}</td>
                        </tr>
                        <tr>
                          <td>Unit</td>
                          <td>:</td>
                          <td>{selectedMedicine.unit}</td>
                        </tr>
                        <tr>
                          <td>Dosage</td>
                          <td>:</td>
                          <td>{selectedMedicine.dosage}</td>
                        </tr>
                        <tr>
                          <td>Strength</td>
                          <td>:</td>
                          <td>{selectedMedicine.strength}</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>:</td>
                          <td>{selectedMedicine.quantity}</td>
                        </tr>
                        <tr>
                          <td>Discontinued</td>
                          <td>:</td>
                          <td>
                            {
                                selectedMedicine.discontinued === true ? 
                                <div className="pro-discont">Product is discontinued, <span onClick={() => handleContinue()} className="change-space">Change?</span></div>
                                :
                                <button className="btn btn-danger ft10" onClick={() => handleDiscontinue()}>Discontinue</button>
                            }
                          </td>
                        </tr>
                    </tbody>
                  </table>
              </div>
             )

          }
        </div>

        {
            processing && (
              <div className="processes-container">
                  <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
              </div>
            )
       }
      </div>
    </div>
  )
}

export default MedicineSingle