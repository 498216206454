import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import * as CONSTANTS from "../CONSTANTS";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { logout } from '../reduxAuth/authSlice';
import { toast } from 'react-toastify';

function Medicine() {
  const {user}                                                                          = useSelector((state) => state.auth);
  const [listMeds, setListMeds]                                                         = useState({});
  const [pageNumber, setPageNumber]                                                     = useState(1);
  const [listLimit, setListLimit]                                                       = useState(100);
  const [processing, setProcessing]                                                     = useState(false);
  const dispatch                                                                        = useDispatch();

  useEffect(() => {
    //console.log(user);
    collectProductList();
  },[])

  useEffect(() => {
    if(pageNumber > 1){
      collectAppendProductList();
    }
    
  },[pageNumber])

  const collectProductList = async () => {
    try{

      setProcessing(true);

      let config  = {
        method: 'put',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL +"medicine/list/pact",
        headers: {
          'token': 'Bearer ' + user.accessToken
        }
      }

      const res       = await axios.request(config);

      //console.log(res.data);
      setListMeds(res.data);
      setProcessing(false);
    }catch(err){  
      console.log(err);
      setProcessing(false);
      //Check if token is invalid     
      if(err.response.status === 403){
          localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
          dispatch(logout());
          toast.error("Session has timeout out.")
          setTimeout(() => {
            document.location.href("/login");
          }, 4000);
      }
    }
  }

  const collectAppendProductList = async () => {
    try{
      setProcessing(true);
     
      const dataFilter = {
        "page" : pageNumber,
        "limit" : listLimit
      }
     
      const res = await axios.put(CONSTANTS.API_URL +"medicine/list/pact/", dataFilter, {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });

      /*
      console.log("----------------------------");
      console.log(res.data);
      console.log(res.data.list);
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
      */

      //setListMeds(res.data);
      setListMeds(prevListMeds => ({
        ...prevListMeds,
        list: [...prevListMeds.list, ...res.data.list]
      }));
      
      setProcessing(false);
    }catch(err){  
      console.log(err);
      setProcessing(false);
    }
  }

  return (
    <div className="box-page">
       <h3>Medicine </h3>
       <div className="box-body">
        <div className="strip-steam">

        </div>
       {
          listMeds &&
          listMeds.list &&
          listMeds.list.length > 0 && (
            <div className="section-medi-table">
               <table className="table table-hover tab-relate">
                <thead>
                  <tr className="head-row-line">
                    <th>#NO</th>
                    <th>Title</th>
                    <th>Nappi Code</th>
                    <th>Active Ingredients</th>
                    <th>Schedule</th>
                    <th>Strength</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listMeds.list.map((medi, index) => {
                      return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{medi.proprietaryName}</td>
                        <td>{medi.nappiCode}</td>
                        <td>{medi.activeIngredient}</td>
                        <td>{medi.schedule}</td>
                        <td>{medi.strength}</td>
                        <td>{medi.unit}</td>
                        <td>{medi.quantity}</td>
                        <td>
                          <Link to={"/medicament/" + medi._id} target="_blank" className="btn btn-arrows">View</Link>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          )
        }
          <div className="navigate-pagination">            
            {
              listMeds &&
              listMeds.next &&
              listMeds.next.navi &&
              listMeds.next.navi ===  "on" && (
                <div className="navigation-next">
                  <button className="btn btn-arrows" onClick={() => setPageNumber( num => num + 1)} disabled={processing}><FaArrowRight /></button>
                </div>
              )
            }
          </div>
       </div>

       {
          processing &&(
            <div className="processes-container">
              <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
            </div>
          )
       }
    </div>
  )
}

export default Medicine