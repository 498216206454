import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import DlgAddToken from '../Dialogue/DlgAddToken';
import DlgDeleteToken from '../Dialogue/DlgDeleteToken';

function SettingsToken({user, CONSTANT}) {

    const [tokenList, setTokenList]                                           = useState([]);
    const [showDialogueToken, setShowDialogueToken]                           = useState(false);
    const [showDialogueDeleteToken, setShowDialogueDeleteToken]               = useState(false);

    const [update, setUpdate]                                                 = useState(0);

    const [selectedId, setSelectedId]                                         = useState("");

    useEffect(()=> {
        getTheTokenList();
    },[update])

    const getTheTokenList = async () => {
      try{
        
        const reponse = await axios.get(CONSTANT.API_URL + "tokens/list", {
          headers: {
              token: "Bearer "+ user.accessToken
          }
        });      

        if(reponse.data.length > 0){
          setTokenList(reponse.data);
        }
      }catch(err){
        console.log(err);
      }
    }

    const handleDeleteToken = (record) => {
      console.log(record);
      setSelectedId(record._id);
      setShowDialogueDeleteToken(true);
    }

  return (
    <div className="widget-card">
      <div className="stream-row">
        <button className="text-button" onClick={() => setShowDialogueToken(true)}>+ Add</button>
      </div>
      <div className="table-content">
        {
          tokenList && 
          tokenList.length > 0 && (
            <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Code</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tokenList.map((tokenRecord, index) => {
                      return <tr key={index}>
                                <td>{tokenRecord.title}</td>
                                <td>
                                  <p className="code">
                                    <code>{tokenRecord.token}</code>
                                  </p>
                                </td>
                                <td > {
                                        new Date(tokenRecord.createdAt).toLocaleDateString(undefined, {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        })
                                      }
                                </td>
                                <td>
                                  <button className="text-button space1">Edit</button> | 
                                  <button className="text-button space2" onClick={() => handleDeleteToken(tokenRecord)}>Delete</button> 
                                </td>
                             </tr>
                    })
                  }
                </tbody>
            </table>
          )
        }
      </div>
      {
        showDialogueToken && (
          <DlgAddToken 
            user={user}
            CONSTANT={CONSTANT}
            setShowDialogueToken={setShowDialogueToken}
            setUpdate={setUpdate}
            />
        )
      }
      {
          showDialogueDeleteToken && (

            <DlgDeleteToken
              user={user}
              CONSTANT={CONSTANT}
              tokenId={selectedId}
              setShowDialogueDeleteToken={setShowDialogueDeleteToken}
              setUpdate={setUpdate}
            />
          )
      }  
    </div>
  )
}

export default SettingsToken