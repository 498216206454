import axios from 'axios';
import React, { useRef, useState } from 'react'
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

function DlgAddToken({user, CONSTANT, setShowDialogueToken, setUpdate}) {

    const tokenNameRef                          = useRef();

    const [processing, setProcessing]           = useState(false);

    const handleCreateToken = async (e) => {
        e.preventDefault();

        try{
            setProcessing(true);
            const tokenBack = {
                "title" : tokenNameRef.current.value
            }
            const response = await axios.post(CONSTANT.API_URL + "tokens/creation", tokenBack, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
              });
              console.log(response.data);      
            setProcessing(false);
            toast.success("Thank you.");
            setUpdate(prev => prev + 1);
            tokenNameRef.current.value = "";
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
  return (
    <div className="dialogue-box">
      <div className="dialogue-body-content">
        <div className="dialogue-header">
            <button className="closing-bt mgright20" onClick={() => setShowDialogueToken(false)}>
              <FaRegTimesCircle />
            </button>
            Add Token

        </div>
        <div className="dialogue-body">
            <form onSubmit={handleCreateToken}>
                <div className="form-group">
                    <input className="form-control" ref={tokenNameRef} placeholder="Token Name..." />
                </div>
                <div className="form-group">
                    <button className="btn btn-action">Create</button>
                </div>
            </form>
        </div>
      </div>
      {
            processing && (
            <div className="processes-container">
                <img src={CONSTANT.SMALL_PROCESSING} className="img-process" />
            </div>
            )
        }
    </div>
  )
}

export default DlgAddToken