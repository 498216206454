import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import { logout } from '../reduxAuth/authSlice';
import { toast } from 'react-toastify';

function StartScreen() {
    const {user}                                                                          = useSelector((state) => state.auth);
    const [processing, setProcessing]                                                     = useState(false);

    const [scheduleItem0, setScheduleItem0]                                               = useState();
    const [scheduleItem1, setScheduleItem1]                                               = useState();
    const [scheduleItem2, setScheduleItem2]                                               = useState();
    const [scheduleItem3, setScheduleItem3]                                               = useState();
    const [scheduleItem4, setScheduleItem4]                                               = useState();
    const [scheduleItem5, setScheduleItem5]                                               = useState();
    const [scheduleItem6, setScheduleItem6]                                               = useState();
    const [scheduleItemTotal, setScheduleItemTotal]                                       = useState();
     
    const dispatch                                                                        = useDispatch();

    useEffect(() => {
        getTotalMedication();
    },[])

    const getTotalMedication = async () => {
        try{
            setProcessing(true);
            const resHome = await axios.get(CONSTANTS.API_URL +"medicine/listing/total/scheduling", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            console.log(resHome);
            setScheduleItem0(resHome.data.S0);
            setScheduleItem1(resHome.data.S1);
            setScheduleItem2(resHome.data.S2);
            setScheduleItem3(resHome.data.S3);
            setScheduleItem4(resHome.data.S4);
            setScheduleItem5(resHome.data.S5);
            if(resHome.data.S6 > 0){
              setScheduleItem6(resHome.data.S6);
            }
            
            setScheduleItemTotal(resHome.data.total);
            
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
            if(err.response.status === 403){
                  localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                  dispatch(logout());
                  toast.error("Session has timeout out.");

                  setTimeout(() => {
                    document.location.href("/login");
                  }, 4000);
            }
        }
    }
  return (
    <div className="box-page">
      <h3>Summary</h3>
      <div className="box-body">
        <div className="row rsx">
          {
            scheduleItem0 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert green-boss">
                   <h3>Schedule 0</h3>
                   <div className="num-schedule">
                    {scheduleItem0}
                   </div>
                </div>
              </div>
            )
          }

          {
            scheduleItem1 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert orange-boss">
                   <h3>Schedule 1</h3>
                   <div className="num-schedule">
                    {scheduleItem1}
                   </div>
                </div>
              </div>
            )
          }

          {
            scheduleItem2 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert red-boss">
                   <h3>Schedule 2</h3>
                   <div className="num-schedule">
                    {scheduleItem2}
                   </div>
                </div>
              </div>
            )
          }

          {
            scheduleItem3 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert purple-boss">
                   <h3>Schedule 3</h3>
                   <div className="num-schedule">
                    {scheduleItem3}
                   </div>
                </div>
              </div>
            )
          }

          {
            scheduleItem4 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert red-boss">
                   <h3>Schedule 4</h3>
                   <div className="num-schedule">
                    {scheduleItem4}
                   </div>
                </div>
              </div>
            )
          }
          {
            scheduleItem5 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert purple-boss">
                   <h3>Schedule 5</h3>
                   <div className="num-schedule">
                    {scheduleItem5}
                   </div>
                </div>
              </div>
            )
          }
          {
            scheduleItem6 && (
              <div className="col-md-4">
                <div className="schedule-feed-alert green-boss">
                   <h3>Schedule 6</h3>
                   <div className="num-schedule">
                    {scheduleItem6}
                   </div>
                </div>
              </div>
            )
          }
          {
            scheduleItemTotal && (
              <div className="col-md-4">
                <div className="schedule-feed-alert blue-boss">
                   <h3>Total Products</h3>
                   <div className="num-schedule">
                    {scheduleItemTotal}
                   </div>
                </div>
              </div>
            )
          }
        </div>
        {
            processing && (
              <div className="processes-container">
                  <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
              </div>
            )
          }
      </div>
    </div>
  )
}

export default StartScreen