import React, { useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';

function MedicineSearch() {

  const {user}                                                                          = useSelector((state) => state.auth);
  const [listSeach, setListSearch]                                                      = useState([]);
  const [searchProcess, setProcessSearch]                                               = useState(false);
  const [searchText, setSearchText]                                                     = useState("");
  const [showDialogSearch, setShowDialogueSearch]                                       = useState(false);
  const [nothingFound, setNothingFound]                                                 = useState("");
  const timeoutRef                                                                      = useRef(null);
  
  const [selectedMedicine, setSelectedMedicine]                                         = useState();

  const handleChangeSearch = (event) => {
      const txtSearch = event.target.value;
      setSearchText(txtSearch);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
  
      // Set a new timeout
      if (txtSearch.length > 2) {
        timeoutRef.current = setTimeout(() => {
          executeSearchNow(txtSearch);
        }, 1000); // Adjust the delay (in milliseconds) as needed
      }
  }

  const executeSearchNow = async (content) =>{
      try{
        setProcessSearch(true);
          
          const obSearch = {
            "searching" : content
          }
          setNothingFound("");
          //console.log(obSearch);
          const resData = await axios.put(CONSTANTS.API_URL +"medicine/search/advanced/", obSearch ,{
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
          
          //console.log(resData);
          if(resData.data.length > 0){
              setListSearch(resData.data);
              setShowDialogueSearch(true);
          }else {
            setNothingFound("Nothing found");
          }
        setProcessSearch(false);
      }catch(err){
        setProcessSearch(false);
        console.log(err);
      }
  }


  const handleSelectMedicene = (element) => {
   
    // console.log(element);
    // console.log("+++++=======++++++");

     setSelectedMedicine(element);
     setListSearch([]);
     setShowDialogueSearch(false);
  }


  return (
    <div className="box-page">
       <h3>Medicine Search</h3>
       <div className="box-body">
          <div className="search-box">
            <input 
                type="text"
                className="searchMain"
                onChange={handleChangeSearch}
                placeholder="Add Search text, 2 characters or more..."
                value={searchText}
                />
                
                {
                  showDialogSearch && (
                    <div className="dialog-search">
                        {
                            listSeach.map((searched, index) => {
                              return <div className="content-search" key={index} onClick={() => handleSelectMedicene(searched)}> 
                                    <strong>{searched.proprietaryName}</strong> 
                                    <span className="mgright5">({searched.activeIngredient}) </span> 
                                    <span className="thin mgright5">{searched.packSize} {searched.dosage}</span> 
                                    <span className="smaller thin">({searched.unit})</span> 
                                  </div>
                            })        
                        }     
                    </div>
                  )
                }
          </div>
          {
             selectedMedicine && (
              <div className="section-semi-table">
                  <table className="table">
                    <tbody>
                        <tr>
                          <td>Proprietary Name</td>
                          <td>:</td>
                          <td>{selectedMedicine.proprietaryName}</td>
                        </tr>
                        <tr>
                          <td>Active Ingredients</td>
                          <td>:</td>
                          <td>{selectedMedicine.activeIngredient}</td>
                        </tr>
                        <tr>
                          <td>Schedule</td>
                          <td>:</td>
                          <td>{selectedMedicine.schedule}</td>
                        </tr>
                        <tr>
                          <td>Nappi Code</td>
                          <td>:</td>
                          <td>{selectedMedicine.nappiCode}</td>
                        </tr>
                        <tr>
                          <td>Pack Size</td>
                          <td>:</td>
                          <td>{selectedMedicine.packSize}</td>
                        </tr>
                        <tr>
                          <td>Unit</td>
                          <td>:</td>
                          <td>{selectedMedicine.unit}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <Link to={"/medicament/" + selectedMedicine._id} className="btn btn-info">
                              More +
                            </Link>
                          </td>
                        </tr>
                    </tbody>
                  </table>
              </div>
             )
          }
          {
            nothingFound.length > 0 && (
              <div className="alert alert-danger">
                {nothingFound}
                </div>
            )
          }
          {
            searchProcess && (
              <div className="processes-container">
                  <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
              </div>
            )
          }
       </div>
    </div>
  )
}

export default MedicineSearch