import axios from 'axios';
import React, { useState } from 'react'
import { FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

function DlgDeleteToken({user, CONSTANT, setShowDialogueDeleteToken, setUpdate, tokenId}) {
    const [processing, setProcessing]               = useState(false);

    const handleDeleteToken = async () => {
        try{
            setProcessing(true);
            const tokenBack = {
                "tokenid" : tokenId
            }
            console.log("ddddeeeellleeee");
            console.log(tokenBack);

            const response = await axios.put(CONSTANT.API_URL + "tokens/removal", tokenBack, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
              });
              console.log(response.data);      
            setProcessing(false);
            toast.success(response.data.message);
            setUpdate(prev => prev - 1);
            setShowDialogueDeleteToken(false);
        }catch(err){
            console.log(err);
        }
    }
  return (
    <div className="dialogue-box">
          <div className="dialogue-body-content">
            <div className="dialogue-header">
                <button className="closing-bt mgright20" onClick={() => setShowDialogueDeleteToken(false)}>
                  <FaRegTimesCircle />
                </button>
                Delete Token
    
            </div>
            <div className="dialogue-body">
                 <button className="btn btn-action" onClick={() => handleDeleteToken()}>Confirm Delete</button>
            </div>
          </div>
          {
                processing && (
                <div className="processes-container">
                    <img src={CONSTANT.SMALL_PROCESSING} className="img-process" />
                </div>
                )
            }
        </div>
  )
}

export default DlgDeleteToken