import React, { useState } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import SettingsToken from '../Components/Others/SettingsToken';
import SettingsProfile from '../Components/Others/SettingsProfile';
import SettingsNotice from '../Components/Others/SettingsNotice';

function Settings() {
  const {user}                                                        = useSelector((state) => state.auth);
  const [activeTab, setActiveTab]                                     = useState('Token');

  const [processing, setProcessing]                                   = useState();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="box-page">
      <h3>Settings</h3>
      <div className="box-body">
        
      <div className="view-tab-content">        
          
            <div className="tab-bar">
              <button 
                className={`tab ${activeTab === 'Token' ? 'active' : ''}`}
                onClick={() => handleTabClick('Token')}
              >
                Token
              </button>
              <button 
                className={`tab ${activeTab === 'Profile' ? 'active' : ''}`}
                onClick={() => handleTabClick('Profile')}
              >
                Profile
              </button>
              <button 
                className={`tab ${activeTab === 'Notice' ? 'active' : ''}`}
                onClick={() => handleTabClick('Notice')}
              >
                Notice
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 'Token' && 
                  <SettingsToken
                    user={user}
                    CONSTANT={CONSTANTS}
                  />} 
              {activeTab === 'Profile' && 
                  <SettingsProfile 
                    user={user}
                    CONSTANT={CONSTANTS}
                  />} 
              {activeTab === 'Notice' && 
                  <SettingsNotice  
                    user={user}
                    />} 
            </div>
        </div>
      </div>  
          {
                processing && (
                  <div className="processes-container">
                      <img src={CONSTANTS.SMALL_PROCESSING} className="img-process" />
                  </div>
                )
            }
    </div>
  )
}

export default Settings